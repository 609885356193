// app/javascript/entrypoints/hero_smart_suite.js
import { createApp } from "vue";
import Vue3Marquee from "vue3-marquee";

import HeroSmartSuite from "../components/frontend/HeroSmartSuite.vue";

// Remove duplicate createApp call - you had two
const app = createApp(HeroSmartSuite);
app.use(Vue3Marquee);
app.mount("#medangle_hero_smart_suite");
