<!-- ProSmartSuiteDarkLayout.vue -->
<template>
  <div class="dark-marquee-section">
    <Vue3Marquee class="dark-marquee-container" :autoplay="true" :duration="45" :pauseOnHover="true" :reverse="false">
      <div v-for="(card, index) in cards" :key="index" class="dark-cardhero" :class="{ 'dark-theme': card.darkTheme }"
        :style="card.img ? {
          backgroundImage: `url(${card.img})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        } : {}">
        <div class="card-overlay" v-if="card.img" :class="{ 'dark-overlay': card.darkTheme }"></div>
        <div class="dark-card-icon">
          <img :src="card.icon" alt="icon" class="icon-image" loading="lazy" />
        </div>
        <div class="content-wrapper">
          <div class="text-content">
            <h2 class="dark-card-title"><span class="ma-full-gradient-text has-text-weight-bold">{{ card.title }}</span>
            </h2>
            <p class="dark-card-description">{{ card.description }}</p>
          </div>
        </div>
        <div class="arrow-circle">
          <span class="arrow">→</span>
        </div>
      </div>
    </Vue3Marquee>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { Vue3Marquee } from 'vue3-marquee'

import { onMounted } from 'vue'

const loadImages = ref(false)

onMounted(() => {
  window.addEventListener('load', () => {
    loadImages.value = true
  })
})

const cards = ref([
  {
    icon: 'https://assets-medangle.b-cdn.net/official/icons/smart-suite/smart-sync.svg',
    img: 'https://scdn.medangle.com/platform/web/super-app-home.png',
    title: 'Smart Sync',
    description: 'Your activity + studying for premed, dental & medical synced on any device.'
  },
  {
    icon: 'https://assets-medangle.b-cdn.net/smart-suite/premium/light/smart-sets.svg',
    title: 'Smart Sets',
    img: 'https://scdn.medangle.com/platform/web/super-app-smart-sets-ios-min.png',
    description: 'Any topic. Any time. Quiz yourself with active learning across 25,000+ high quality MCQs.'
  },
  {
    icon: 'https://assets-medangle.b-cdn.net/smart-suite/premium/light/smart-search.svg',
    title: 'Smart Search',
    description: 'Find what you\'re looking for instantly with our powerful real-time search engine.'
  },
  {
    icon: 'https://assets-medangle.b-cdn.net/official/icons/fire-emoji.svg',
    img: 'https://scdn.medangle.com/platform/web/super-app-ios-night-mode-min.png',
    title: 'Night Mode',
    description: 'Light Mode. Dark Mode. Night Mode. Customize your aesthetic and style.',
    darkTheme: true
  },
  {
    icon: 'https://assets-medangle.b-cdn.net/smart-suite/premium/light/smart-stats.svg',
    title: 'Smart Stats',
    description: 'Know your strengths and weaknesses with beautiful and detailed performance statistics.'
  },
  {
    icon: 'https://assets-medangle.b-cdn.net/smart-suite/premium/light/smart-summaries.svg',
    img: 'https://scdn.medangle.com/platform/web/super-app-smart-summaries-ios.png',
    title: 'Smart Summaries',
    description: 'Easy, efficient and fast concise summaries of whatever you need to learn.'
  },
  {
    icon: 'https://assets-medangle.b-cdn.net/smart-suite/premium/light/smart-start.svg',
    title: 'Smart Start',
    description: 'Resume wherever you leave off. We remember so you don\'t have to.'
  },
  {
    icon: 'https://assets-medangle.b-cdn.net/smart-suite/premium/light/smart-scripts.svg',
    img: 'https://scdn.medangle.com/platform/web/super-app-smart-scripts-ios.png',
    title: 'Smart Scripts',
    description: 'Drugs and pharmacology made easy and efficient.'
  },
  {
    icon: 'https://assets-medangle.b-cdn.net/smart-suite/premium/light/smart-saves.svg',
    title: 'Smart Saves',
    description: 'Save your questions instantly. Access them anytime, anywhere.'
  }
])
</script>

<style scoped>
.dark-marquee-section {
  padding: 2rem 0;
  overflow: hidden;
}

.dark-marquee-container {
  height: auto;
  display: flex;
  align-items: center;
}

.dark-cardhero {
  background-color: #efefef;
  color: #111;
  width: 360px;
  min-height: 480px;
  margin: 1rem;
  padding: 1.5rem;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
  position: relative;
  overflow: hidden;
}

.dark-cardhero.dark-theme {
  background-color: #111;
  color: #fff;
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(239, 239, 239, 0.0), rgba(255, 255, 255, 1.00));
  pointer-events: none;
}

.card-overlay.dark-overlay {
  background: linear-gradient(to bottom, rgba(17, 17, 17, 0.0), rgba(17, 17, 17, 1.00));
}

.dark-cardhero:hover {
  transform: translateY(-4px);
}

.dark-card-icon {
  width: 64px;
  height: 64px;
  margin-bottom: auto;
  position: relative;
}

.icon-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.content-wrapper {
  margin-top: auto;
  padding-right: 48px;
  position: relative;
}

.text-content {
  max-width: calc(100% - 48px);
}

.dark-card-title {
  margin: 0;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.2;
}

.dark-card-description {
  margin-top: 0.5rem;
  font-size: 1rem;
  line-height: 1.4;
  opacity: 0.9;
}

.dark-theme .dark-card-description {
  color: #fff;
}

.arrow-circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
  transition: background-color 0.2s ease;
  z-index: 1;
}

.dark-theme .arrow-circle {
  background-color: rgba(255, 255, 255, 0.1);
}

.dark-cardhero:hover .arrow-circle {
  background-color: rgba(0, 0, 0, 0.2);
}

.dark-theme:hover .arrow-circle {
  background-color: rgba(255, 255, 255, 0.2);
}

.arrow {
  font-size: 1.2rem;
  line-height: 1;
}

.dark-theme .arrow {
  color: #fff;
}
</style>